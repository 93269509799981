import logo from './logo.svg';
import './App.css';
import {Route, BrowserRouter as Router, Switch, Link} from 'react-router-dom'
import insuranceBlock from './InsuranceBlock/InsuranceBlock';

import MainPage from './MainPage/MainPage';
import SideBar from './SideBar/SideBar';
import Form_Top from './Form_Top/Form_Top';
import Form_1 from './Form/Form_1';
import Form_2 from './Form/Form_2'
import Modalz from './Modal/Modalz';
import Form_Page from './Form_Page/Form_Page';
import { StateProvider } from './core/app-context';
import LandingPage from './LandingPage/LandingPage';



function App() {
  return (
    <StateProvider>
    <Router>
    <Switch>
      <Route exact path='/' component={MainPage} />
     

    </Switch>
  </Router>
</StateProvider>

  );
}

export default App;
