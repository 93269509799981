import React from "react";
import {Box, Tabs, TabList, TabPanels, Tab, TabPanel, } from "@chakra-ui/react"
import './InsuranceBlock.css';

import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,Center
  } from "@chakra-ui/react"

  import Modalz from '../Modal/Modalz';

function insuranceBlock(props) {




  

  
  //const productPrice = fetchedproducts.slice(0,5).map(product=><p style={{borderBottom:' 1px solid #CCCFD8'}}>{product.ProductPrice*product.ProductQty}</p>)
          

  return (<div className="blocky">


<Box className="top_left">
<img style={{width:'80px',height:'40px', backgroundColor:'gray', marginTop:'16px', marginLeft:'24px', display:'inline-block'}} />

<Box style={{display:'inline-block', marginLeft:'16px'}}>
  <p className='blue_header'>{props.name}</p>
  <p className='sub_header'>{props.insure_type}</p>
</Box>

<Center>
<Box>
<p className="price_amount">{(props.prem_cost*props.price).toLocaleString()} MT
<span className="sub_header"><br/>/annual premium</span></p>
</Box>

<Box style={{marginLeft:'80px'}}>
<p className="price_amount">{(props.prem_cost*props.price/2).toLocaleString()} MT
<span className="sub_header"><br/>/semetral</span></p>
</Box>

<Box style={{marginLeft:'80px'}}>
<p className="price_amount">{(props.prem_cost*props.price/3).toLocaleString("en", {   
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
})} MT
<span className="sub_header"><br/>/trimestral</span></p>
</Box>
<Box className='modal_blocky'>

<Modalz name = {props.name}       annual_prem = {(props.prem_cost*props.price).toLocaleString()} 
biannual_prem = {(props.prem_cost*props.price/2).toLocaleString()}
trimestral_prem = {(props.prem_cost*props.price/3).toLocaleString("en", {   
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
})}/>

</Box>

</Center>

<Center>
<Accordion allowToggle>
  <AccordionItem>
  
    

    <AccordionPanel style={{width:'112vh'}} >
     
    <Tabs>
  <TabList>
    <Tab style={{width:'121px'}} className="panel_button">Own Damage</Tab>
    <Tab style={{width:'164px', marginLeft:'16px'}}  className="panel_button">Third Party Liability</Tab>
    <Tab style={{width:'186px', marginLeft:'16px'}}  className="panel_button">Extensions of Coverage</Tab>
  </TabList>
  <TabPanels className="panel">
    <TabPanel>
      <p>Yes we Know it works</p>
    </TabPanel>
    <TabPanel>
      <p>Now lets Make a billion</p>
    </TabPanel>
    <TabPanel>
      <p>Dollars little Niggas</p>
    </TabPanel>
  </TabPanels>
</Tabs>


     
    </AccordionPanel>

    <AccordionButton style={{border:'none', backgroundColor:'#fff'}}>
      <Box flex="1" textAlign="center">
          <p className='details_btn'>Details <AccordionIcon /></p>
          </Box>
        
        
      </AccordionButton>
  </AccordionItem>
  </Accordion>

</Center>


</Box>
        
          
         

           
    </div>
    );

}

export default insuranceBlock