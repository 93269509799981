import React from 'react';
import './Header.css';
import {Box,Center, Spacer, Nav} from '@chakra-ui/react';
import Logo from './Logo.svg'



function Header() {


    return(
<Box className='MainHead'>

<img style={{display:'inline-block', marginLeft:'9%', position:'absolute'}} src={Logo} width='142px' height='72px' />


<Box style={{display:'inline-block', height:'72px', position:'absolute', right:'0', width:'311px', marginRight:'120px', marginTop:'6px'}}>
<p style={{display:'inline-block', marginRight:'80px'}} className='textStyle'>About Us</p>

<p style={{display:'inline-block',}} className='textStyle'>Compare Insurance</p>
</Box>

</Box>

    );


}

export default Header;