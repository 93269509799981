import {useContext, useState, React, useEffect } from 'react';
import {Box} from '@chakra-ui/react'
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import api from "./../core/api";
import { AppContext } from "../core/app-context";

import InsuranceBlock from '../InsuranceBlock/InsuranceBlock'
import SideBar from '../SideBar/SideBar';


function MainPage() {



 const [appState, setAppState] = useContext(AppContext);


    const [fetchedproducts, setFetchedproducts] = useState([]);

    const [Insurers, setInsurers] = useState([]);
    
    const [pricey, setPricey] = useState(0);
    
 
  


//const [company_id, setCompanyID] = useState("");

const handleclick = () => {

  setPricey(appState.userDetails.carvalue.value);;
  //alert(JSON.stringify(appState))
}



    const insurers =  [{
        "id":1,
        "insurance_name":"Diamond Companhia de Seguros",
        "premium_cost": '0.050',
        "excess_cost":'0.1',
        "logo": ''
}, { "id":2,
"insurance_name":"Imperial Insurance",
"premium_cost": '0.060',
"excess_cost":'0.1',
"logo": ''},
{"id":3,
"insurance_name":"ARISeguros",
"premium_cost": '0.040',
"excess_cost":'0.1',
"logo": ''},
{"id":4,
"insurance_name":"Hollard",
"premium_cost": '0.052',
"excess_cost":'0.2',
"logo": ''},
{"id":5,
"insurance_name":"Palma Companhia De Seguros",
"premium_cost": '0.040',
"excess_cost":'0.1',
"logo": ''},
{"id":6,
"insurance_name":"Global Alliance Seguros",
"premium_cost": '0.060',
"excess_cost":'0.2',
"logo": ''},
{"id":7,
"insurance_name":"Indico Seguros",
"premium_cost": '0.050',
"excess_cost":'0.1',
"logo": ''},
{"id":8,
"insurance_name":"Britam",
"premium_cost": '0.050',
"excess_cost":'0.075',
"logo": ''}];




const insurer_name = insurers.map(insurer=><p>{insurer.insurance_name}</p>)


    return(

        <Box className='whole_page' style={{width:'99.89%', height:'100%', position:'fixed'}}>

<Box style={{height:'8%', width:'100%'}}>
        <Header />
        </Box>



        <Box style={{marginTop:'12px', marginLeft:'0%', display:'inline-block', width:'15%', height:'72%', position:'fixed'}}>
        
        <SideBar onSearch={()=>{
            handleclick();
        }}/>


        </Box>


        <Box className='block_scroll'  style={{width:'77%', height:'82%', display:'inline-block', marginLeft:'21%', marginTop:'3%' , position:'absolute',overflowY:'auto'}}>


        {insurers.map((e)=>{
       return (
       <InsuranceBlock name={e.insurance_name} prem_cost ={e.premium_cost} price = {pricey}  insure_type ={appState.userDetails.typeofinsurance.value}/>
     );})}


        </Box>





        </Box>
    )


}

export default MainPage