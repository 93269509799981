
import {Box, Center} from '@chakra-ui/react'
import './APage.css'
import Logo from './Logo.svg'
import { AppContext } from "../core/app-context";
import {useContext, useState, React, useEffect, Component, PropTypes } from 'react';




function APage(props) {

const [appState, setAppState] = useContext(AppContext);



const print = ()=> {
    window.print()
}

    return(

<Box>
   
<button className='submitt_btn' onClick={print}>Send</button>

<div class="book">
    <div class="page">
        <div class="subpage">
 <Box style={{height:'20px', width:'200px'}}></Box>
<Box style={{width:'100%', marginLeft:'24px'}}>
          
          
        <Box style={{display:'inline-block'}}>

          <img alt='logo' style={{width:'208px', height:'80px', backgroundColor:'#c4c4c4'}} />
        
        <p className='p_top_left'>
        Av. Mao Tse Tung<br/>
Parcela 141 - R/C<br/>
C. Postal Nº 2659<br/>
Mozambique<br/>
<strong>NUIT</strong>: 40003255630
        </p>
        </Box>
        <Box style={{width:'300px', height:'200px', display:'inline-block', float:'right', marginRight:'80px'}}>
        <p className='cli_details'>DD/MM/YYYY</p>
        <p className='cli_details'>
        <strong>Client Details</strong> <br/>
        {props.namey}<br/>
        {props.emaily}<br/>
        {props.celly}
          </p>

        </Box>



        <Box style={{width:'90%', height:'20px'}}>
          
          <p style={{ marginLeft:'45%', fontSize:'25px'}}>Quote</p>
          
          </Box>


<Box style ={{width:'90%', height:'auto', marginTop:'8px'}}>
  
<p className='body_header'>Client Details</p>
  <table className="customers">
  <tr>
    <th className='table_text'>Occupation Status</th>
    <th className='table_text'>Gender</th>
    <th className='table_text'>Age</th>
    <th className='table_text'>City</th>
  </tr>
  <tr>
    <td className='table_text'>{ appState.userDetails.occupationstatus.value}</td>
    <td className='table_text'>{ appState.userDetails.persongender.value}</td>
    <td className='table_text'>{ appState.userDetails.personage.value}</td>
    <td className='table_text'>{appState.userDetails.personcity.value}</td>
  </tr>
 
</table>

</Box>


<Box style ={{width:'90%', height:'auto', marginTop:'8px'}}>
  <p className='body_header'>Vehicle Details</p>

  <table className="customers">
  <tr>
    <th className='table_text'>Vehicle Manufacturer</th>
    <th className='table_text'>Model</th>
    <th className='table_text'>Year</th>
    <th className='table_text'>Value</th>
    <th className='table_text'>Km Travelled</th>
    <th className='table_text'>Type of Use</th>
  </tr>

  <tr>
    <td className='table_text'>{ appState.userDetails.vehiclemanu.value}</td>
    <td className='table_text'>{ appState.userDetails.carmodel.value}</td>
    <td className='table_text'>{ appState.userDetails.caryear.value}</td>
    <td className='table_text'>{ appState.userDetails.carvalue.value}</td>
    <td className='table_text'>{ appState.userDetails.kmtravelled.value}</td>
    <td className='table_text'>{ appState.userDetails.typeofuse.value}</td>
  </tr>
 
</table>

</Box>



<Box style ={{width:'90%', height:'auto', marginTop:'5px'}}>
  <p className='body_header'>Option 1</p>

  <table className="customers">
  <tr>
    <th className='table_text'>Insurer</th>
    <td className='table_text'>{props.insurerName}</td>
    <th className='table_text'>Coverage Type</th>
    <td className='table_text'>{appState.userDetails.typeofinsurance.value}</td>
  </tr>
  <tr>
    <th className='table_text'>3 month premium</th>
    <td className='table_text'>{props.trimestral_prem}</td>
    </tr>
    <tr>
    <th className='table_text'>6 month premium</th>
    <td className='table_text'>{props.biannual_prem}</td>
    </tr>
    <tr style={{width:'2000px'}}>
    <th className='table_text'>12 month premium</th>
    <td className='table_text'>{props.annual_prem}</td>
    </tr>

    <tr>
    <th className='table_text'>Benefits</th>
    <td className='table_text' >Text here</td>
    
    </tr>
 
</table>

</Box>


<Box style ={{width:'90%', height:'auto', marginTop:'5px'}}>
  <p className='body_header'>Option 2</p>

  <table className="customers">
  <tr>
    <th className='table_text'>Insurer</th>
    <td className='table_text'>Company Name here</td>
    <th className='table_text'>Product Name</th>
    <td className='table_text'>Coverage type</td>
  </tr>
  <tr>
    <th className='table_text'>3 month premium</th>
    <td className='table_text'>Text here</td>
    </tr>
    <tr>
    <th className='table_text'>6 month premium</th>
    <td className='table_text'>Text here</td>
    </tr>
    <tr>
    <th className='table_text'>12 month premium</th>
    <td className='table_text'>Text here</td>
    </tr>

    <tr>
    <th className='table_text'>Benefits</th>
    <td className='table_text'>Text here</td>
    </tr>
 
</table>

</Box>



<Box style ={{width:'90%', height:'auto', marginTop:'5px'}}>
  <p className='body_header'>Option 3</p>

  <table className="customers">
  <tr>
    <th className='table_text'>Insurer</th>
    <td className='table_text'>Company Name here</td>
    <th className='table_text'>Product Name</th>
    <td className='table_text'>Coverage type</td>
  </tr>
  <tr>
    <th className='table_text'>3 month premium</th>
    <td className='table_text'>Text here</td>
    </tr>
    <tr>
    <th className='table_text'>6 month premium</th>
    <td className='table_text'>Text here</td>
    </tr>
    <tr>
    <th className='table_text'>12 month premium</th>
    <td className='table_text'>Text here</td>
    </tr>

    <tr>
    <th className='table_text'>Benefits</th>
    <td className='table_text'>Text here</td>
    </tr>
 
</table>

</Box>
<Box style={{marginTop:'20px', display:'inline-block'}}>
<p className='btm_text'>To view details of insurers/products information or to start the registration process:</p>
<p className='btm_text'>Call us on: <span style={{marginLeft:'20px'}}><strong>05226 55633652</strong></span></p>
<p className='btm_text'>Email us: <span style={{marginLeft:'20px'}}><strong>  agentname@brokername</strong></span></p>
</Box>

<Box style={{display:'inline-block', marginLeft:'50px'}} >
<img src={Logo} width='56px' height='32px'/>
</Box>
</Box>

        
        
        </div>    
    </div>
    <div class="page">
        <div class="subpage">Page 2/2</div>    
    </div>
   
    
</div>


</Box>
    )



}


export default APage;