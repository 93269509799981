import React, {useContext, useState } from "react";
import './SideBar.css'
import {Box, Center} from '@chakra-ui/react';
import { AppContext } from "../core/app-context";






function SideBar(props) {


const [appState, setAppState] = useContext(AppContext);

const [vehman, setVehman] = useState("");
const [model, setModel] = useState("");
const [year, setYear] = useState("");
const [value, setValue] = useState("");
const [tOy, setToy] = useState("");
const [kmT, setKmt]  = useState("");
const [ocu, setOcu] = useState("");
const [insure, setInsure] = useState("");
const [gender, setGender] = useState("");
const [ age, setAge] = useState("");
const [city, setCity]= useState("");




 const handleSubmit = () => {




setAppState((prevState: any) => {
                        let newState = prevState;
                        newState.userDetails.vehiclemanu.value = vehman;
                        newState.userDetails.carmodel.value = model;
                        newState.userDetails.caryear.value = year;
                        newState.userDetails.carvalue.value = value;
                        newState.userDetails.typeofuse.value = tOy;
                        newState.userDetails.typeofinsurance.value = insure;
                        newState.userDetails.kmtravelled.value = kmT;
                        newState.userDetails.occupationstatus.value = ocu;
                        newState.userDetails.persongender.value = gender;
                        newState.userDetails.personage.value = age;
                        newState.userDetails.personcity.value = city;

                        return newState;
                    });

props.onSearch();
 }







    return(
        <div style={{width:'100vh', height:'100vh'}}>
        <Box className='block'>
    <Box style={{height:'1px', width:'100%', backgroundColor:'#FAFBFD'}}>

    </Box>

            <Box style={{marginLeft:'19px'}}>
            <h1 className='sidebar_header'>Vehicle Details</h1>

           
            <label className='labels'>Vehicle Manufacturer</label>
            <input className='inputs_sidebar' style={{width:'90%'}} type='text' value={vehman} onChange={({ target }) => setVehman(target.value)} />
           

            <Box style={{marginTop:'-16px'}}>
                <label className='labels'>Model</label>
                <input className='inputs_sidebar' style={{width:'90%'}} type='text' value={model} onChange={({ target }) => setModel(target.value)} />
            </Box>

            <Box style={{marginTop:'-16px'}}>


<div className="container">
    <div className="child"><label className='labels'>Year</label>
            <input className='inputs_sidebar' style={{width:'103px'}} type='text' value={year} onChange={({ target }) => setYear(target.value)} /></div>
    <div className="child"><label className='labels' style={{marginLeft:'-13px'}}>Value</label> 
            <input className='inputs_sidebar' style={{width:'125px', marginLeft:'-13px'}} type='text' value={value} onChange={({ target }) => setValue(target.value)} /></div>
  </div>
  
            </Box>

            <Box style={{marginTop:'-16px'}}>
            <div className="container">
    <div className="child"><label className='labels' >Km Travelled</label>
            <input className='inputs_sidebar' style={{width:'103px'}} type='text' value={kmT} onChange={({ target }) => setKmt(target.value)} /></div>
    <div className="child"><label className='labels'  style={{marginLeft:'-13px'}}>Type of Use</label>

<select className='inputs_sidebar' style={{width:'125px', marginLeft:'-13px'}} value={tOy} onChange={({ target }) => setToy(target.value)}>
  <option value = ""> </option>
  <option value="Personal">Personal</option>
  <option value="Business">Business</option>
</select>

            </div>
  </div>
            </Box>

<label className='labels'>Type of Insurance</label>
            <select className='inputs_sidebar' style={{width:'90%'}} type='text' value={insure} onChange={({ target }) => setInsure(target.value)} >
            <option value = ""> </option>
  <option value="3rd Party">3rd Party</option>
  <option value="3rd Party with ocupants">3rd Party with ocupants</option>
  <option value="Full Coverage">Full Coverage</option>
</select>



        <div style={{width:'93%', height:'0px', borderBottom:'1px solid #BDBFC1', marginLeft:'-2%', marginTop:'14px'}}></div>

            <h1 className='sidebar_header'>Client Details</h1>

            <Box style={{marginTop:'-16px'}}>
            <label className='labels'>Occupation Status</label>
            <input className='inputs_sidebar' style={{width:'90%'}} type='text' value={ocu} onChange={({ target }) => setOcu(target.value)} />
</Box>
            

<Box>
<label className='labels' style={{display:'block'}}>Gender</label>
<div className="container">

    <div className="child">
           <input className='inputs_sidebar' style={{width:'20px'}} type='radio' checked={true} value="Male" onChange={({ target }) => setGender(target.value)} /></div>
    <div className="child">
            <input className='inputs_sidebar' id='female' style={{width:'20px', marginLeft:'-13px'}} checked={false} type='radio' value="Female" onChange={({ target }) => setGender(target.value)} /></div>
  </div>
  </Box>
           
<Box>
<div className="container">
    <div className="child"><label className='labels'>Age</label>
            <input className='inputs_sidebar' style={{width:'103px'}} type='text' value={age} onChange={({ target }) => setAge(target.value)} /></div>
    <div className="child"><label className='labels'  style={{marginLeft:'-13px'}}>City</label>
            <input className='inputs_sidebar' style={{width:'125px', marginLeft:'-13px'}} type='text'  value={city} onChange={({ target }) => setCity(target.value)} /></div>
  </div>
  </Box>
            <button onClick={handleSubmit} className='edit_btn'>Search</button>
           
        </Box>


</Box>
        </div>
    );
}

export default SideBar;