import { createContext, useState } from 'react';

let initialState: any = {
    userDetails: {
       
        vehiclemanu: {
            value: "",
            label: "vehicle manufacturer",
            placeholder: "vehicle manufacturer",
            type: "",
            errorMsg: "",
            required: true
        },
        carmodel: {
            value: "",
            label: "car model",
            placeholder: "car model",
            type: "",
            errorMsg: "",
            required: true
        },
        caryear: {
            value: "",
            label: "car year",
            placeholder: "car year",
            type: "",
            errorMsg: "",
            required: true
        },
         carvalue: {
            value: "",
            label: "car value",
            placeholder: "car value",
            type: "",
            errorMsg: "",
            required: true
        },
         typeofuse: {
            value: "",
            label: "type of use",
            placeholder: "type of use",
            type: "",
            errorMsg: "",
            required: true
        },
         typeofinsurance: {
            value: "",
            label: "type of insurance",
            placeholder: "type of insurance",
            type: "",
            errorMsg: "",
            required: true
        },
         kmtravelled: {
            value: "",
            label: "km travelled",
            placeholder: "km travlled",
            type: "",
            errorMsg: "",
            required: true
        },
         occupationstatus: {
            value: "",
            label: "occupation status",
            placeholder: "occupation status",
            type: "",
            errorMsg: "",
            required: true
        },
         persongender: {
            value: "",
            label: "person gender",
            placeholder: "person gender",
            type: "",
            errorMsg: "",
            required: true
        }
        , personage: {
            value: "",
            label: "person age",
            placeholder: "person age",
            type: "",
            errorMsg: "",
            required: true
        },
         personcity: {
            value: "",
            label: "person city",
            placeholder: "person city",
            type: "",
            errorMsg: "",
            required: true
        }
    }
  
};

export const AppContext = createContext(initialState);

export const StateProvider = (props: any) => {
    const [appState, setAppState] = useState(initialState);

    return (
        <AppContext.Provider value={[appState, setAppState]}>
            {props.children}
        </AppContext.Provider>
    );
}