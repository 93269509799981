import {React, useState} from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton, Button, Text, useDisclosure, Lorem, Center, Box
  } from "@chakra-ui/react"

  import Modal_Logo from './Modal_Logo.svg'
  import './Modal.css'
  import APage from '../APage/APage';


  


function Modalz(props) {
    const { isOpen, onOpen, onClose } = useDisclosure()

    const [name, setName]= useState("");
    const [email, setEmail]= useState("");
    const [number, setNumber]= useState("");
  

    const handleSubmit =() => {


      alert (`Your name is ${name}, your phone number is ${number}, email is ${email}`)
    }




    return (
      <>
        <button className='button_request' onClick={onOpen}>Request</button>
  
        <Modal className='ModalBody' blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
          <ModalOverlay style={{backgroundColor:'rgba(0,0,0,0.5)', position:'fixed'}} />
          <ModalContent  style={{width:'486px', height:'434px', backgroundColor:'#fff',borderRadius:'5px', position:'absolute',marginLeft:' 35%', marginTop:'5%', marginRight:'auto'}}>
            <ModalHeader className='ModalBody' style={{height:'48px', width:'486px',backgroundColor:'#2660A4', borderRadius:'5px 5px 0px 0px'}}>
              
            <img src={Modal_Logo} alt='compara_logo' height='32px' width='64px' style={{marginLeft:'24px', 
            display:'inline-block', marginTop:'8px'}} />
             <p className='modal_title'>Request Form</p>
             
            </ModalHeader>
          
            <ModalBody className='ModalBody' >
            <Box className="top_left">
<img style={{width:'80px',height:'40px', backgroundColor:'gray', marginTop:'16px', marginLeft:'24px', display:'inline-block'}} />

<Box style={{display:'inline-block', marginLeft:'16px'}}>
  <p className='blue_header'>{props.name}</p>
  <p className='sub_header'>Product Name - Coverage Range</p>
</Box>
             </Box>

<Box style={{width:'406px', marginLeft:'40px'}}>

  
      <p className='sub_title'>Please provide the details that you would like to be contacted<br/> on for the insurance coverage. </p>

      <input className='inputs_s' placeholder='Full Name' type='text' value={name} onChange={({ target }) => setName(target.value)} />
      <input className='inputs_s' placeholder='Cell No' type='text' value={number} onChange={({ target }) => setNumber(target.value)} />
      <input className='inputs_s' placeholder='Email' type='text' value={email} onChange={({ target }) => setEmail(target.value)} />


      </Box>

            </ModalBody>
  
            <ModalFooter  style={{width:'406px', marginLeft:'80px'}}>
              <Center>
              <button  className='cancell_btn' mr={3} onClick={onClose}>
                Close
              </button>
              
                <APage onClick={handleSubmit} namey={name}  emaily={email} celly={number} insurerName = {props.name} 
                annual_prem = {props.annual_prem} biannual_prem = {props.biannual_prem}
                trimestral_prem = {props.trimestral_prem}/>
              
              </Center>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
  }

  export default Modalz;